.container {
  background-color: #ececec;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  padding: 2%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: smaller;

  p {
    color: #262543;
  }

  @media (min-width: 768px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 3%;
    padding-right: 3%;
    flex-direction: row;
    font-size: small;
  }

  @media (min-width: 1020px) {
    font-size: xx-large;
  }

  .instruction {
    color: #726464;
    width: 85%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    gap: 1rem;

    p {
      font-size: 15px;
    }

    img {
      position: relative;
      width: 15%;
    }

    @media (min-width: 768px) {
      color: #726464;
      flex-direction: column;

      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      text-align: center;
      padding-bottom: 1.5rem;

      p {
        font-size: small;
      }

      img {
        position: relative;
        width: 40%;
      }
    }

    @media (min-width: 1020px) {
      color: #726464;
      flex-direction: column;

      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      text-align: center;

      p {
        font-size: 20px;
        color: #262543;
      }

      img {
        position: relative;
        width: 22%;
      }
    }
  }
}
