@import '../../../../../global.css';

.main_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 15vh;
  min-height: 100vh;

  .buttons_container {
    display: flex;
    gap: 1rem;

    @media (max-width: 700px) {
      flex-direction: column;
      gap: 0.5rem;
      padding-bottom: 0.5rem;

      button {
        margin: 0;
        width: 90vw;
      }
    }
  }

  button {
    width: 250px;
    height: 36px;
    border-radius: 5px;
    background: var(--color-orange-500) !important;
    border: none;
    color: aliceblue;
    margin-bottom: 2.5rem;
    font-weight: 500;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 1060px) {
      width: 200px;
    }

    @media (max-width: 890px) {
      width: 150px;
    }
  }

  a {
    text-decoration: none;
  }
}

.unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  cursor: pointer;

  &:nth-child(2n) {
    background: var(--color-gray-200);
  }

  p {
    padding: 1.2rem;
    margin: 0;
  }
}

.unit:hover {
  background-color: aqua;
  border-radius: 0.2rem;
}
