.use_terms_container {
  position: fixed;
  bottom: 0vh;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  width: 100%;
  font-weight: 500;
  color: #ffff;

  span {
    margin-left: 0.3rem;
    cursor: pointer;
  }
}
