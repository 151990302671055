@mixin flex_div($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

.container_modal {
  @include flex_div(center, center);
  flex-direction: column;
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);

  .content_modal {
    @include flex_div(flex-start, center);
    flex-direction: column;
    padding: 2rem;
    background: #fff;
    max-width: 800px;
    max-height: 380px;
    border-radius: 1.5rem;

    @media (max-width: 900px) {
      max-width: 600px;
      max-height: 480px;
    }

    @media (max-width: 660px) {
      max-width: 600px;
      max-height: 500px;
    }

    @media (max-width: 520px) {
      max-width: 450px;
      max-height: 600px;
    }

    span {
      color: rgba(0, 0, 0, 0.6);
    }

    .modal_buttons {
      @include flex_div(flex-end, center);
      width: 100%;
      gap: 1rem;
    }
  }
}
