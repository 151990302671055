.container {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem 1rem;
  gap: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;

    h1 {
      margin: 0;
      padding: 0;
    }

    button {
      margin-top: -1rem;
      width: 100%;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    box-shadow: 2px 2px 4px 4px var(--color-gray-400);
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: relative;
    z-index: 0;

    .icon {
      position: absolute;
      bottom: 12px;
      right: 56px;
    }

    .delete_icon {
      position: absolute;
      bottom: 12px;
      right: 20px;
    }

    svg {
      cursor: pointer;
    }

    .content {
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}
