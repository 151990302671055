.container_form {
  button,
  div,
  input {
    width: 100%;
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
  }
}
