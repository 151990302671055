@media (min-width: 0px) {
  .header img {
    width: 128px;
  }

  .header {
    padding: 0rem 0.5rem;
  }
}

@media (min-width: 1024px) {
  .header img {
    width: 196px;
  }

  .header {
    padding: 0rem 2rem;
  }
}

.header {
  position: relative;
  z-index: 1;
  height: 4rem;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;

  left: 0;

  width: 100%;

  z-index: 999px;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
}
.header a {
  margin-left: 0%;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  position: relative;
}

.icon {
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
}

.FakeButton {
  background-color: #2e358f;
  width: auto;
  padding: 10%;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  text-align: center;
  color: #ffff;
  font-weight: 400;
  text-decoration: none;

  transition: transform 0.2s;

  padding: 0.6rem;
}
.FakeButton:hover {
  transform: scale(1.1);
}

.ButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

Link {
  background-color: #ffff;
}
