.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 0.5rem;
  max-width: 27rem;
  height: 450px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media (min-width: 1440px) {
    height: 390px;
  }

  @media (max-width: 1280px) {
    max-width: 46rem;
  }

  .content {
    margin-top: 9rem;
  }

  img {
    width: 100%;
    height: 11rem;
    border-radius: 0.5rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem;
  }

  strong {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
}
