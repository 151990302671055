.container {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #ffff;
  padding-bottom: 5%;
  padding-top: 0;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80%;
    background-color: #ececec;
    padding-top: 1.5%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: start;

    img {
      width: 300px;
    }

    p {
      font-weight: 600;
      font-size: 15px;
      color: #262543;
    }

    @media (min-width: 1020px) {
      flex-direction: row;

      img {
        width: 600px;
      }

      p {
        font-weight: 600;
        font-size: 25px;
      }

      h3 {
        font-size: 30px;
        font-weight: 500;
      }
    }

    .text_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #262543;
      padding: 10%;
      width: 100%;
      height: 100%;

      .link_button {
        background-color: #fc5b11;

        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 20px;
        text-align: center;
        color: #ffff;
        text-decoration: none;
        width: 300px;
        height: 40px;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.01);
        }
      }

      @media (min-width: 1020px) {
        padding: 5%;
      }
    }
  }
}
