.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .selects {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    option:disable {
      color: white;
    }

    div,
    select {
      width: 100%;
    }
  }
}
