.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;

  p {
    color: black;
  }
}
