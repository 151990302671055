.popup {
  flex-direction: column;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
}

.map {
  width: 100%;
  height: 20rem;
  margin-right: 5rem;
}

.mainContainer {
  padding: 0.2rem;
}

@media (min-width: 760px) {
  .popup {
    flex-direction: row;
    padding: 3rem 6rem;
  }

  .map {
    width: 500px;
    height: 350px;
    margin-right: 5rem;
  }

  .mainContainer {
    padding: 2rem;
  }
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 4rem;
  width: 100%;
  height: 100%;
}

.popup {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 25px;
  width: 100%;
  padding: 1rem 1rem;
}

.mainTitle {
  color: #575757;
}

.informationsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.priceContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 5vh;
  height: 80%;
}

.info {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}

.price {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .scholarships {
    display: flex;
    align-items: flex-end;
    gap: 5px;

    svg {
      margin-bottom: 3px;
    }
  }
}

.leftContainer {
  width: 100%;
}

.info {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  text-align: start;
  width: 100%;
  flex-direction: column;
}

.info span {
  font-size: medium;
}

.rightContainer {
  background-color: #2e358f;
  padding: 2rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150%;
  width: 100%;
}

.rightContainer p {
  color: #ffff;
}

.FakeButton {
  color: #ffff;
}

span {
  color: #ff974c;
}

h1,
h2,
h3 {
  color: white;
}

h3 {
  font-size: large;
  text-align: center;
  width: 100%;
}

.h4 {
  color: #575757;
  font-weight: bold;
}

.title {
  font-size: x-large;
}

.FakeButton {
  background-color: #fc5b11;
  width: 90%;
  height: 4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 20px;
  text-align: center;
  color: #ffff;
  text-decoration: none;
  transition: transform 0.2s;
}

Link {
  background-color: #ffff;
}

.line {
  height: 0.01rem;
  width: 100%;
  background-color: #ffff;
  margin-bottom: 3%;
}

.lineTwo {
  width: 80%;
  height: 0.1rem;
  background-color: #575757;
  margin-top: 3%;
  margin-bottom: 3%;
}

.Carousel {
  align-items: center;
  display: flex;
  justify-content: center;
}
