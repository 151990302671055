@media (min-width: 0px) {
  .background {
    padding-bottom: 2rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-top: 10%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .backgroundTwo {
    height: 100%;
    width: 100%;
    padding: 2rem;
    border-radius: 2rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .column {
    padding: 1rem;
  }
  .columnTwo {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1020px) {
  .background {
    padding-top: 5%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .backgroundTwo {
    flex-direction: row;
  }
  .column {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .columnTwo {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .backgroundTwo {
    height: 90%;
    width: 100%;
    padding: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.background {
  background-color: #262543;
}
.column {
  background-color: #ececec;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: double;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}
.column img {
  width: 0%;
}
.column input {
  background-color: #888888a0;
  border-radius: 10px;
  width: 100%;
  height: auto;
  border: none;
  color: #ffff;
}
.column input:focus {
  border-radius: 10px;
  border: 0;
  border-color: #26254300;
  color: #ffff;
}
.column button {
  background-color: #fc5b11;
  color: #ffff;
  border: none;
  border-radius: 10px;
  width: 5rem;
  height: 2rem;
}
.text p {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: bold;
  color: #373737;
  margin-bottom: 2%;
}
.text2 p {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: bold;
  color: #373737;

  font-size: 150%;
}

.columnTwo {
  background-color: #ffff;
  align-items: center;
  display: flex;
  width: 100%;
}
.columnTwo img {
  width: 100%;
  height: 100%;
}
.bar {
  background-color: #262543;
  width: 35%;
  height: 1px;
  margin: 2%;
}

.backgroundTwo {
  margin-top: 4rem;
  border-radius: 2rem;
}
