@mixin step_statusbar($step_percent) {
  width: $step_percent;
}

.statusbar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  height: 2rem;
  width: 73%;
  margin-top: 4rem;
  border-radius: 20px;

  background-color: #d9d9d9;
}

.statusbar div {
  display: flex;
  height: 100%;
  border-radius: 20px;
  background-color: #2e358f;
}

.statusbar_step_1 div {
  @include step_statusbar(25%);
}

.statusbar_step_2 div {
  @include step_statusbar(50%);
}

.statusbar_step_3 div {
  @include step_statusbar(75%);
}

.statusbar_step_4 div {
  @include step_statusbar(100%);
}
