@import '../../../../../../global.css';

.image_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .image_content {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    background: var(--color-gray-200);
    padding: 2rem;
    border-radius: 5px;

    button {
      margin-bottom: -0.8rem;

      &:disabled {
        background: var(--color-gray-200) !important;
      }
    }
  }

  @media (max-width: 700px) {
    width: 90vw;

    button {
      width: 100%;
    }
  }
}
