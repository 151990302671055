.bigSquare {
  border-radius: 30px 30px 0px 0px;
  background-color: #2e358f;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding: 4rem;
  gap: 4rem;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
    color: #ffff;
    font-weight: bolder;
    font-size: larger;
    font-size: 2rem;
  }

  .partners_school_container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1200px) {
      display: grid;
      grid-gap: 1rem;
    }

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 1020px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1rem;
    }

    .partners_school {
      background-color: #fff;
      width: 180px;
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      border-radius: 20px;
      padding: 20px;
      text-align: center;
      color: #262543;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0.4rem 2rem;
        background-color: #2e358f;
        color: #fff;
        border-radius: 0.5rem;
        font-weight: 500;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 5rem;
      }

      p {
        min-height: 2.5rem;
        color: #262543;
        font-weight: bolder;
        font-size: 10px;
        margin: 0;
      }
      @media (min-width: 768px) {
        background-color: #fff;

        p {
          color: #262543;
          font-weight: bolder;
          font-size: medium;
        }
      }

      @media (min-width: 1020px) {
        background-color: #fff;
        width: 220px;
        height: 220px;

        img {
          width: 90px;
          height: 90px;
        }

        p {
          color: #262543;
          font-weight: bolder;
          font-size: 12px;
          font-weight: bolder;
        }
      }

      .image_container {
        width: 90%;
        height: 90%;
      }
    }
  }
}
