.background {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin-top: 7%;
}

.redirect {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .link_button {
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;

    width: 300px;
    height: 40px;
    border-radius: 20px;

    background-color: #fc5b11;
    color: #ffff;
    text-decoration: none;

    transition: transform 0.2s;

    &:hover {
      transform: scale(1.01);
    }
  }
}

@media (min-width: 1020px) {
  .background {
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 3rem;
    padding-top: 3rem;
    margin-top: 5%;
  }

  .link_button {
    width: 600px;
    height: 40px;
  }
}

.background {
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .title {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
