.container {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  width: 80%;

  p {
    font-size: larger;
    font-family: nunito;
    font-weight: bolder;
    font-size: xx-large;
    color: #888888;
    margin: 0;
  }
}
