.container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.3rem;

    svg {
      margin-top: 5px;
    }

    .error {
      border-color: red;

      &:focus {
        border-color: red;
        border-width: 1px;
      }
    }

    input {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border: 1px solid hsl(0, 0%, 73%);
      border-radius: 10px;
      width: 100%;
      height: 2.5rem;
      margin-top: 1%;
      color: #000;
      padding: 1rem;

      transition: transform 0.2s;

      &:focus {
        border-color: #2e358f;
        border-width: 2px;
      }
    }
  }

  p {
    color: #888888;
    font-family: nunito;
    font-weight: bolder;
    align-self: flex-start;
    margin: 0;
  }
}
