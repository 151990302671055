.select_container {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  min-width: 400px;
  padding: 1rem;
  gap: 2rem;
  box-shadow: 0px 0px 2px 2px #bbb;

  select {
    width: 130px;
    height: 24px;
  }
}

.select_content {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    margin: 0;
    width: 80px;
  }
}
