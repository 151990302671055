.curso {
  display: grid;
  grid-template-columns: 48% 2% 15% 2% 30%;
  gap: 1rem;
  margin: 1rem 0;

  .content_button {
    display: flex;
    gap: 1rem;
  }

  button {
    max-height: 2rem;
  }

  @media (max-width: 700px) {
    background: var(--color-gray-200);
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border-radius: 5px;

    p:nth-child(2),
    p:nth-child(4) {
      display: none;
    }

    &:nth-child(2n) {
      background: var(--color-gray-300);
    }

    .content_button {
      margin: 1.2rem 0 -1.6rem;
    }
  }
}
