.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 2.2rem 2.2rem;
  margin-top: -3rem;
  margin-bottom: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;

    @media (min-width: 1600px) {
      padding-right: 2rem;
    }

    a {
      text-decoration: none;
      font-size: 1rem;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    width: 100%;
    justify-content: center;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
