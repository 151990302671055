.social_media {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 80%;

  p {
    padding: 1rem 0;
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  select {
    background-color: #9e9b9b;
    color: #ffff;
    font-family: nunito;
    font-weight: bolder;
    border-radius: 10px;
    height: 2rem;
    padding: 0.2rem 0.4rem;

    @media (max-width: 460px) {
      padding: 0.2rem 5rem 0.2rem 2rem;
    }
  }

  @media (max-width: 460px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    text-align: left;
  }
}
