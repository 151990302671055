.container_form {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 5rem;
}

.double_input {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 80%;
  gap: 2rem;

  @media (max-width: 460px) {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
}

.doubleInput div:first-child {
  display: flex;
}

.doubleInput div:last-child {
  margin-left: 0%;
  display: flex;
}
