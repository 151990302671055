.container_pagination {
  display: flex;
  margin: 1rem 0 2rem;
  justify-content: center;

  button,
  > span {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    align-items: center;
    gap: 8px;
    color: 1px solid #213770;
    border: 1px solid #d0d5dd;
    background: #ffffff;

    &:first-child,
    &:last-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  > span {
    padding: 6px 16px 12px;
  }
}
