.checkbox_container {
  width: 80%;
  display: flex;
  justify-content: flex-end;

  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 460px) {
      gap: 1rem;
      padding-right: 0.5rem;
    }
  }

  .switch_text:not(.switch_text_checked) {
    padding-right: 0.3rem;
    font-size: 1rem;
    color: red;
  }

  .switch_wrapper {
    display: inline-block;
    width: 24px;
    height: 15px;
    position: relative;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .switch_button {
        background-color: #fc5b11;

        &::before {
          transform: translateX(8px);
        }
      }
    }

    .switch_button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 40px;

      background-color: #475569;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 2px;
        bottom: 1.3px;
        border-radius: 50%;
        transition: 0.4s all ease;
        background-color: aliceblue;
      }
    }
  }
}
