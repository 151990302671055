.forms {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
}

.container_form {
  display: flex;
  flex-direction: column;
  margin-top: 4.5rem;

  .form_fields {
    @media (max-width: 800px) {
      flex-direction: column;
    }

    display: flex;
    width: 100%;

    .content_form {
      border-radius: 20px;
      align-items: flex-start;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    .double_input {
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 2rem;

      div:first-child {
        display: flex;
      }

      div:last-child {
        margin-left: 0%;
        display: flex;
      }
    }
  }

  .actions {
    @media (max-width: 460px) {
      flex-direction: column;
    }

    width: 100%;
    display: flex;

    > div + div {
      justify-content: flex-end;
      gap: 1rem;
      width: 100%;

      @media (max-width: 460px) {
        justify-content: center;
        align-items: center;
      }
    }
  }

  .status {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    // margin: 24px 0 0 8px;

    p {
      padding-top: 5px;
    }

    select {
      height: 30px;
    }

    @media (max-width: 460px) {
      justify-content: center;
      font-size: 20px;
    }
  }
}
