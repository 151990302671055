.footer_buttons {
  @media (max-width: 460px) {
    justify-content: center;
    gap: 1rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 2.5rem 0;
  gap: 1rem;

  a {
    text-decoration: none;
  }
}

.buttons {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 4rem;
  gap: 1rem;
}
