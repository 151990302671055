.caroussel {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 3rem;
  width: 100%;
}
.caroussel img {
  width: 600px;
  margin-top: 2rem;
}
.BigGroupSquare {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 0%;
}
.GroupSquare {
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 1rem;
  margin-bottom: 5%;
  flex-direction: row;
}
.lilGroupSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 1rem;
  width: 100%;
  height: 100%;
}
.Square {
  background-color: #ffff;

  border-radius: 20px;
  padding: 10px;
  transition: transform 0.2s;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Square:hover {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .BigGroupSquare {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 0%;
  }
  .GroupSquare {
    align-items: center;
    justify-content: space-between;
    display: flex;
    gap: 1.5rem;
    margin-bottom: 5%;
    flex-direction: row;
  }
  .lilGroupSquare {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 1.5rem;
    width: 100%;
    height: 100%;
  }
  .Square {
    background-color: #ffff;

    border-radius: 20px;
    padding: 20px;
    transition: transform 0.2s;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .Square:hover {
    transform: scale(1.04);
  }
}

@media (min-width: 1020px) {
  .BigGroupSquare {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 0%;
  }
  .GroupSquare {
    align-items: center;
    justify-content: space-between;
    display: flex;
    gap: 1.5rem;
    margin-bottom: 5%;
    flex-direction: row;
  }
  .lilGroupSquare {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    gap: 1.5rem;
    width: 100%;
    height: 100%;
  }
  .Square {
    background-color: #ffff;

    border-radius: 15px;
    padding: px;
    transition: transform 0.2s;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .Square:hover {
    transform: scale(1.1);
  }
  .caroussel img {
    width: 100%;
    margin-top: 2rem;
  }
}

@media (max-width: 1200px) {
  .filter {
    flex-direction: column;
  }
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.caroussel {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0rem;
  width: 100%;
}

.background {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  margin: auto;
  margin-top: 2rem;
  margin-left: auto;
  padding: 2%;
  height: 85%;
  width: 95%;

  background-color: #ffff;
  box-shadow: 2rem;
  box-shadow: 0px 0px 10px 3px #00000051;
  border-radius: 20px;
}

.background button {
  margin-bottom: 2rem;
  height: 2rem;
  width: 8rem;
  border: none;
  border-radius: 10px;
  background-color: #fc5b11;
  color: #fff;
}
.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1.5rem;
  font-weight: bold;
  font-size: large;
}

.button p {
  font-size: xx-large;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 3%;
  color: #2e358f;
  font-weight: bold;
}
.column select {
  color: #2e358f;
  background-color: #ffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size-adjust: unset;
  border: solid 1px;
  border-color: #2e358f;
  border-radius: 10px;
  height: 2.5rem;
  width: 15rem;
}

.p {
  font-size: large;
}

.Parceiras {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

Link {
  background-color: #ffff;
}
.span {
  text-decoration: line-through;
  color: #ba4712;
}

.homem {
  width: 600px;
  height: 600px;
  margin-right: 4rem;
}

.subHeaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -130px;
  margin-top: 50px;
  padding: 0rem 10rem;
}

.subHeaderTitle {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  line-height: 70px;
}

.subHeaderTitle p {
  color: #333;
  font-size: 3rem;
}

.subHeaderTitle span {
  color: #2e358f;
  font-size: 4rem;
}

.subHeaderTitleOrange {
  color: #fc5b11;
}

.escolaParceiraTitulo {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.aligh {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.aligh p {
  margin: 0;
}
