h1 {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

p {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

.container_form {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2rem;

  .content_form {
    background-color: #fff;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 90%;
    padding-bottom: 1rem 0 2rem 0;

    .container_institute {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 80%;
      gap: 2rem;
      font-size: 1.8rem;

      .image {
        height: 100%;

        img {
          border-radius: 1.5rem;

          @media (max-width: 460px) {
            width: 300px !important;
            height: 200px;
          }
        }
      }

      .course_information {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        span {
          font-size: 1.5rem;
        }
      }

      @media (max-width: 460px) {
        flex-direction: column;
      }
    }
  }
}
