:root {
  --color-success: #1a961a;

  --color-orange-200: #e3885a;
  --color-orange-500: #fc5b11;

  --color-gray-200: #f0f0f0;
  --color-gray-300: #e7d0d0;
  --color-gray-400: #a89494;

  --color-white: #ffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-orange-200);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-gray-200);
}
