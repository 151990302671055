@media (min-width: 1020) {
  .info {
    margin-right: 0%;
    margin-bottom: 0%;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  z-index: 100;

  background-color: #151279;
  padding: 5px;
  margin-right: 3%;
  margin-bottom: 3%;

  img {
    width: 45px;
    height: 45px;
    margin-top: 0.9rem;
  }
}

.wpp {
  display: flex;
  align-items: center;
  justify-content: center;
}
