.selected_content > div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  select {
    min-width: 50px;
  }
}
