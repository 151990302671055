.container {
  padding: 1rem 2rem 3rem;
  width: 100%;
  background: #4b49b9;
  color: #ffff;

  > h3,
  span,
  p,
  ::placeholder {
    color: #ffff !important;
  }

  ::placeholder {
    color: #141212 !important;
  }

  h1 {
    color: #ffff;
    padding: 1rem 0 0;
    text-align: center;
  }

  h3 {
    font-size: 1.4rem;
  }

  button {
    font-weight: 700;
  }

  .content_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 5rem;
    }

    .content_image {
      width: 100%;
      height: 100%;

      img {
        margin: 1.5rem 0 0rem;
        padding-bottom: -5rem;
        object-fit: contain;
        height: 100%;
        width: 100%;
        max-height: 400px;
      }
    }
  }
}
