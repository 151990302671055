@import '../../../../../../global.css';

img {
  width: 40vw;
  height: 50%;
  margin-bottom: 1rem;
}

.container {
  padding: 1rem;
  width: 80vw;
  background: var(--color-gray-200);
  display: grid;
  grid-template-columns: 30% 70%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    width: 80vw;
    cursor: pointer;

    .editing {
      display: flex;
      flex-direction: column;

      button {
        margin: 0.5rem 0 -0.5rem 2.5rem;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .editing {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 1rem 0 0 !important;
    }

    input {
      width: 90%;
      padding-right: 1rem;
    }
  }
}
