.list_pe {
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15vh;

  select,
  p {
    margin-bottom: 1rem !important;
  }

  .loading {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: orange;

    h1 {
      font-size: 42px;
    }
  }

  .search {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    input {
      border-radius: 1rem;
    }

    select {
      padding: 0.2rem;
    }

    button {
      border-radius: 0.5rem;
    }
  }

  .table_container {
    @media (max-width: 1015px) {
      width: 100%;

      table {
        width: 100%;
      }

      thead {
        display: none;
      }

      tbody,
      td {
        display: flex;
        flex-direction: column;
      }
    }

    thead {
      background: var(--color-orange-500);
      color: var(--color-gray-200);
    }

    table,
    th,
    td {
      margin-bottom: 1rem;
    }

    tr {
      font-weight: 500;

      &:first-child {
        font-weight: 900;
      }

      &:nth-child(2n) {
        background: var(--color-gray-300);
      }
    }

    td {
      padding: 0.5rem;

      &:last-child {
        border-bottom: none;
      }
    }

    svg {
      cursor: pointer;
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 8px 1rem;
    }
  }
}
