.footer_container {
  display: grid;
  grid-template-columns: 10% 30% 25% 25%;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;

  background-color: #fc5b11;
  color: #ffff;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: bold;
    color: #ffff;
    margin: 0;
  }

  span {
    color: #ffff;
  }

  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

.hour {
  flex-direction: column;
  align-items: center;
  height: 5rem;
  padding: 1rem;

  border-radius: 20px;
  border: 1px solid;
  color: #ffff;
  border-color: #ffff;

  p {
    color: #ffff;
    font-size: 15px;
    margin: 0%;
    padding: 0;
  }

  @media (min-width: 1020px) {
    min-width: 260px;
    flex-direction: column;
    gap: 10px;

    p {
      font-size: 90%;
      margin: 0%;
    }
  }
}

.background {
  background-color: #262543;
  width: 100%;
  margin-bottom: 0%;
  height: 16rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  color: #ffff;
  padding: 5%;
  bottom: 0;
  gap: 1rem;

  h4 {
    color: #ffff;
    font-weight: bold;
  }

  @media (min-width: 760px) {
    height: 10rem;
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (min-width: 1020px) {
    height: 10rem;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 7%;
    padding-top: 6%;
  }
}

@mixin default_column {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: small;
  width: 100%;
  height: 100%;

  h4 {
    font-size: medium;
  }

  a {
    color: #ffff;
    text-decoration: none;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  p {
    color: #ffff;
    display: flex;
    font-size: smaller;
    text-align: center;
  }

  @media (min-width: 760px) {
    a {
      text-align: center;

      &:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: large;
    }

    h4 {
      font-size: large;
      text-align: center;
    }
  }

  @media (min-width: 1020px) {
    a {
      font-size: larger;

      &:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: larger;
    }

    h4 {
      font-size: x-large;
    }
  }
}

.column {
  @include default_column();

  span {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}

.line {
  width: 0.1rem;
  height: 3rem;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 760px) {
    height: 4rem;
  }

  @media (min-width: 1020px) {
    height: 5rem;
  }
}

.backgroundBottom {
  padding: 2%;

  p {
    color: #ffff;
    font-size: 10px;
    text-align: center;
  }

  @media (min-width: 760px) {
    p {
      font-size: 15px;
    }
  }

  @media (min-width: 1020px) {
    border-top: 1px solid;
    border-color: #ffff;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #262543;
  }
}

.social {
  color: #ffff;
  text-decoration: none;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
}

.email {
  @include default_column();

  h1 {
    font-size: 1.5rem;
  }

  a {
    gap: 0.5rem;
    font-size: 1rem;
  }
}
