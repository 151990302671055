.partner_school {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 4rem 0 0;

  a {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .first_section {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    margin-top: 4rem;

    h3 {
      text-align: start;
      margin-bottom: 2rem;
    }

    h1 {
      max-width: 650px;
      font-size: 4rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 460px) {
      flex-direction: column;
      display: flex;
      gap: 1rem;

      h1 {
        font-size: 3rem;
      }
    }
  }

  .second_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0 3rem;
    width: 100%;

    h1 {
      max-width: 610px;
      word-break: keep-all;
      text-align: center;
      font-weight: 800;
      margin-bottom: 3rem;
    }

    .benefits_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 1rem;
      width: 100%;
    }
  }

  .contact {
    box-shadow: 0 0 15px 3px #bbb;
    padding: 1rem;
    width: 90%;
    border-radius: 0.5rem;

    img {
      height: 24px;
      width: 24px;
      margin-top: 2px;
    }

    @media (min-width: 478px) {
      .small_mobile {
        display: none;
      }
    }

    @media (max-width: 477px) {
      strong {
        margin-bottom: 1rem;
      }

      .responsive {
        display: none;
      }
    }

    strong {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      color: #080808;
      font-size: large;

      img {
        margin-bottom: 0;
      }
    }

    @media (max-width: 600px) {
      strong {
        flex-direction: column;
      }
    }
  }

  .third_section {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: column;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;

    h2 {
      font-size: 42px;
      font-weight: 700;
      text-align: center;
    }

    h4 {
      font-weight: 500;
    }
  }
}
