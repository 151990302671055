@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .mainContainer {
    padding: 5rem 1rem;
  }

  .mainContainer h1 {
    font-size: 25px;
  }

  .title {
    p {
      font-size: medium;
      color: black;
    }
  }
}

@media (min-width: 1024px) {
  .mainContainer {
    padding: 5rem 10rem;
  }

  .mainContainer h1 {
    font-size: 10px;
  }

  .title {
    p {
      font-size: x-large;
      color: black;
    }
  }
}

.container {
  width: 100%;
}

.filtro {
  display: flex;
}

.cursosContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0rem;
  grid-gap: 6rem;
  grid-row-gap: 2rem;
  width: 100%;

  @media (max-width: 760px) {
    padding: 1rem;
    overflow-x: auto;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 20rem;
  border-radius: 2rem;
  border: 1px solid;
}

.curso p {
  color: #646464;
  margin: 0%;
  font-weight: bold;
}
.curso h3 {
  color: #4e4e4e;
  font-weight: bolder;
  font-size: large;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  width: 100%;
}

.mainContainer h1 {
  color: #262543;
  font-weight: bolder;
}

span {
  font-weight: bolder;
  font-size: large;
}
