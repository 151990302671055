.footer_buttons {
  @media (max-width: 460px) {
    justify-content: center;
    gap: 1rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 2.5rem;
  margin: 0.5rem 0 2.5rem 0;

  a {
    text-decoration: none;
  }

  &.flex_end {
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
  }

  &.flex_start {
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }
}
