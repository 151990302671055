.background {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  padding: 3rem;
}
p {
  color: #606060;
}
h2 {
  color: #262547;
  text-align: left;
}
h4 {
  color: #262543;
  font-weight: bold;
}
