@media (min-width: 0px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    width: 100%;
    height: 100%;
  }
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    padding: 2rem;
    border-radius: 30px;
    width: 90%;
    text-align: center;
  }
  h3 {
    color: #fc5b11;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: justify;
    text-align-last: center;
    white-space: normal;
  }
}
@media (min-width: 768px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-left: 3rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
  }
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    padding: 2rem;
    border-radius: 30px;
  }
  h3 {
    color: #fc5b11;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 1020px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-left: 3rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
  }
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    padding: 2rem;
    border-radius: 30px;
  }
  h3 {
    color: #fc5b11;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
