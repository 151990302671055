.form_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 700px) {
    width: 85%;
    justify-content: center;

    button {
      width: 100%;
    }
  }
}
