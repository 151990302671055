.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10vh;

  .documents {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3rem;

      > button {
        margin-left: 1rem;
      }
    }
  }
}

.unit {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.curso {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.registrations {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  cursor: pointer;
}

.inscricao {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: white;
  flex-direction: column;
  padding: 1rem;
  border-radius: 2rem;
}

.inscricao p {
  color: white;
}

.inscricaoValueContainer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 90%;
}

.inscricaoValueContainer div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.students {
  padding-bottom: 1rem;

  strong {
    font-size: 1.1rem;
  }

  .student_content {
    width: 100%;
    display: flex;
    gap: 1rem;

    span {
      padding-right: 1rem;
    }
  }
}

h1 {
  margin-top: 1rem;
}

.header_buttons {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;

  button {
    border-radius: 0.4rem;
  }
}
