@mixin default_styles($color) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  margin-top: 1rem;

  button {
    background: $color;
    color: #ffff;
    border-radius: 10px;
    width: 140px;
    height: 100%;
    border: none;

    &:hover {
      opacity: 0.7;
      transition: background-color 0.2s;
    }

    &:disabled {
      opacity: 0.5;
      transition: background-color 0.2s;
    }
  }
}

.default_button {
  @include default_styles(#2e358f);
}

.second_button {
  @include default_styles(#fc5b11);
}
