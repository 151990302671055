.benefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 420px;
  border: 1px solid #dcd7d5;
  border-radius: 0.6rem;
  gap: 1rem;
  padding: 2rem;

  span {
    background: #edc2ae;
    padding: 8px;
    border-radius: 50%;
    opacity: 0.7;
  }

  svg {
    background: #e6926b;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
  }

  h2 {
    font-weight: 700;
  }

  p {
    font-size: 24px;
    max-width: 420px;
  }

  .button_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
