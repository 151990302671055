@media (min-width: 0px) {
  .background {
    margin-top: 2rem;
  }
}
h1 {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}
p {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}
.background {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.line {
  height: 2rem;
  width: 73%;
  display: flex;
  background-color: #d9d9d9;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
}
.line div {
  height: 100%;
  width: 25%;
  display: flex;
  border-radius: 20px;
  background-color: #2e358f;
}
.lineTwo {
  height: 2rem;
  width: 90%;
  display: flex;
  background-color: #d9d9d9;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
}
.lineTwo div {
  height: 100%;
  width: 50%;
  display: flex;
  border-radius: 20px;
  background-color: #2e358f;
}
.backgroundTwo {
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  padding-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 0%;
  padding-right: 0%;
}
.title {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  width: 80%;
}
.title p {
  font-size: larger;
  font-family: nunito;
  font-weight: bolder;
  font-size: xx-large;
  color: #888888;
  margin: 0;
}
.input {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

select {
  background-color: #888888;
  color: #ffff;
  font-family: nunito;
  font-weight: bolder;
  border-radius: 10px;
}
.input p {
  color: #888888;
  font-family: nunito;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0;
}
.input input {
  background-color: #fff;
  border: 1px solid #bbb;
  /* box-shadow: 0px 0px 30px 1px #BBB; */
  border-radius: 10px;
  width: 100%;
  height: 2.5rem;
  margin-top: 1%;
  color: #000;
  padding: 1rem;

  transition: transform 0.2s;
}

.input input:focus {
  border-color: #2e358f;
  border-width: 2px;
  transform: scale(1.02);
}

.doubleInput {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 80%;
  gap: 2rem;
}

.doubleInput input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.doubleInput div:first-child {
  display: flex;
}
.doubleInput div:last-child {
  margin-left: 0%;
  display: flex;
}
.Next {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  width: 80%;
  height: 4rem;
  gap: 1rem;
}
.Next button {
  background-color: #2e358f;
  color: #ffff;
  border-radius: 10px;
  height: 3rem;
  border: none;
  font-weight: 500;
}
.NextTwo {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 80%;
  height: 2.5rem;
  margin-top: 1rem;
}
.NextTwo button {
  background-color: #fc5b11;
  color: #ffff;
  border-radius: 10px;
  width: 20%;
  height: 100%;
  border: none;
}
.FakeButton {
  background-color: #fc5b11;
  width: 20%;
  height: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 20px;
  text-align: center;
  color: #ffff;
  text-decoration: none;

  transition: transform 0.2s;
}
.FakeButton:hover {
  transform: scale(1.1);
}
Link {
  background-color: #ffff;
}
