h1 {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

p {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

.container {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 4.5rem 0 1.8rem;
}

.content_form {
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  padding-bottom: 1rem 0 2rem 0;
}

.input p {
  color: #888888;
  font-family: nunito;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0;
}

.double_input {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 80%;
  gap: 2rem;
}

.select_content {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 85%;
  margin-left: 0%;
  gap: 2rem;
}

.select {
  flex-direction: column;
  gap: 0px;
  padding-bottom: -5px;
  margin: 8px 0 8px 0;

  p {
    font-size: 18px;
  }

  select {
    padding: 4px;
  }
}

.buttons {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}
