h1 {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

p {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

.container {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.content_form {
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  padding-bottom: 1rem 0 2rem 0;
}

.input p {
  color: #888888;
  font-family: nunito;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0;
}

.double_input {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 80%;
  gap: 2rem;

  @media (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
}

.double_input div:first-child {
  display: flex;
}

.double_input div:last-child {
  margin-left: 0%;
  display: flex;
}

.use_terms {
  width: 80%;
  display: grid;
  grid-template-columns: 78% 26%;

  @media (max-width: 860px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
}
