@import '../../../../global.css';

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10vh;
  min-height: 100vh;

  button {
    width: 250px !important;
    background: var(--color-orange-500) !important;
    margin-bottom: 2.5rem;
  }

  a {
    text-decoration: none;
  }
}

.unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  cursor: pointer;

  &:nth-child(2n) {
    background: var(--color-gray-200);
  }

  p {
    padding: 1.2rem;
    margin: 0;
  }
}

.unit:hover {
  background-color: aqua;
  border-radius: 0.2rem;
}
