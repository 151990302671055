h1 {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

p {
  color: #888888;
  font-weight: bolder;
  font-family: nunito;
}

.container {
  background-color: #ffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 4rem;
}

.content {
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  padding-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 0%;
  padding-right: 0%;
}

.back_button {
  text-decoration: none;

  button {
    width: 160px;
  }
}
