.main_container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: 1100px;
  min-width: 720px;

  border: 1px solid;
  border-color: #bbb;
  border-radius: 2rem;
  box-shadow: 0px 0px 15px 3px #bbb;
  padding: 2rem 2rem 0rem 2rem;

  transition: transform 0.2s;

  p {
    margin: 0;
  }

  @media (max-width: 760px) {
    max-width: 700px;
    min-width: 380px;
  }

  @media (max-width: 700px) {
    max-width: 600px;
    min-width: 340px;
  }
}

.right_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .titulo {
    text-align: left;
    font-size: 30px;

    span {
      color: red;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .detalhe {
    font-size: 1rem;

    span {
      color: red;
      font-weight: 700;
    }
  }

  .scholarships {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      color: red;
      font-weight: 600;
    }
  }
}

.container {
  height: 400px;
  flex-direction: column;
}

.container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
}

.footer {
  display: grid;
  grid-template-columns: 60% 30%;

  width: 100%;
  max-height: 40%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 1340px) {
    display: flex;
    flex-direction: column;
  }

  .footer_unit {
    font-size: 1.2rem;
    color: #333;
    padding: 1rem;

    @media (max-width: 1340px) {
      width: 100%;
    }

    @media (max-width: 700px) {
      display: none;
    }

    border-collapse: collapse;

    th {
      padding: 5px 22px;
    }

    td {
      padding: 5px 6px;
      text-align: center;
      word-break: keep-all;
    }
  }

  .footer_unit_mobile {
    @media (min-width: 701px) {
      display: none;
    }

    display: flex;
    flex-direction: column;
    gap: 1rem;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  .footer_buttons {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    width: 100%;
    padding-bottom: 20px;
    align-items: flex-start;
    justify-content: flex-end;

    @media (max-width: 1340px) {
      width: 100%;
      flex-direction: row;
      display: flex;
    }

    div {
      display: flex;
      flex-direction: column;
    }

    a {
      text-decoration: none;
    }
  }
}

.left_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  gap: 1rem;

  img {
    width: 180px;
    height: 180px;
    border-radius: 1rem;
  }

  p {
    font-size: large;
    color: #333;
  }
}

.queroEssaBolsa {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border: none;
  border-radius: 1rem;
  background-color: #fc5b11;
  color: white;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #262543;
}

@media (max-width: 1020px) {
  .mainContainer {
    height: fit-content;
  }

  .container {
    grid-template-rows: 1.5fr 3fr;
  }

  .footer {
    flex-direction: column;
  }
}

@media (min-width: 1020px) {
  .container {
    flex-direction: row;
  }

  .mainContainer {
    height: 30rem;
    width: 100%;
  }
  .footer_buttons {
    flex-direction: row;
    display: flex;
  }
  .titulo {
    font-size: xx-large;
  }
}
