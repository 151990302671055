.container {
  margin: 2rem 0;

  img {
    object-fit: contain;
    cursor: pointer;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid rgb(214, 209, 209);
  }

  .partners {
    :global {
      .swiper-pagination {
        bottom: -5px !important;
        margin-top: 1rem;
      }
    }
  }
}
